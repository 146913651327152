<template>
  <GradientBackLayout class="CommunityConfig"
    :title="$t('community_personalization')"
    :shadowText="$t('personalization')">
    <BoxShadow class="CommunityConfig--Box">
      <div class="CommunityConfig--Tabs">
        <div :class="{'active':selected == 0}" v-on:click="selected = 0">
          <i class="fas fa-palette"></i>
          <span>{{$t('community_config_title_branding')}}</span>
        </div>
        <div :class="{'active':selected == 1}" v-on:click="selected = 1">
          <i class="fas fa-file-contract"></i>
          <span>{{$t('community_config_title_signup')}}</span>
        </div>
        <div :class="{'active':selected == 2}" v-on:click="selected = 2">
          <i class="fas fa-home"></i>
          <span>{{$t('community_config_title_welcome')}}</span>
        </div>
        <div :class="{'active':selected == 3}" v-on:click="selected = 3">
          <i class="fas fa-award"></i>
          <span>{{$t('community_config_title_gamification')}}</span>
        </div>
        <div :class="{'active':selected == 4}" v-on:click="selected = 4" v-if="isCommunityMainAdmin">
          <i class="fas fa-users-cog"></i>
          <span>{{$t('community_config_title_moderators')}}</span>
        </div>
      </div>
      <BoxShadow class="CommunityConfig--Content">
        <GamificationDetails v-show="selected == 3" :gamificationData="gamificationData" v-on:open-image="openImage($event)"/>
        <Branding v-show="selected == 0" :personalization="personalization" v-on:open-image="openImage($event)"/>
        <SignupDetails ref="SignupDetails" v-if="selected == 1" :signUpData="signUpData" v-on:open-image="openImage($event)"/>
        <WelcomeDetails v-show="selected == 2" :welcomeInfo="welcomeData" v-on:open-image="openImage($event)"/>
        <ClientsDetails v-show="selected == 4" />
        <div class="WelcomeDetails--LoginUrl">
            <label>{{$t('community_backoffice_url')}}</label>
              <a target="_blank" :href="url" v-on:click="copyUrl($event)"><i class="fas fa-link"></i>{{url}}</a>
            <div class="WelcomeDetails--UrlCopied">
              <i class="fas fa-clipboard-check"></i>
              {{ $t('videochat_url_copied') }}
            </div>
          </div>
        <div class="CommunityConfig--Actions">
          <CommunityButton type="EL" v-on:click="saveData()" v-show="showSaveButton" :disabled="isObservator">{{$t('action_save')}}</CommunityButton>
        </div>
      </BoxShadow>
    </BoxShadow>
    <div class="CommunityConfig--Image" v-if="showImage">
      <div class="CommunityConfig--Close" v-on:click="showImage = false">
        <i class="fas fa-slash"></i>
        <i class="fas fa-slash"></i>
      </div>
      <img src="../assets/img/examples/login.png" v-if="image == 'LOGIN'">
      <img src="../assets/img/examples/signup.png" v-if="image == 'SIGNUP'">
      <img src="../assets/img/examples/email.png" v-if="image == 'EMAIL'">
      <img src="../assets/img/examples/signup_accept.png" v-if="image == 'SIGNUP_ACCEPT'">
      <img src="../assets/img/examples/terms.png" v-if="image == 'TERMS'">
      <img src="../assets/img/examples/moderator.png" v-if="image == 'MODERATOR'">
      <img src="../assets/img/examples/labs.png" v-if="image == 'LABS'">
      <img src="../assets/img/examples/community.png" v-if="image == 'COMMUNITY'">
      <img src="../assets/img/examples/favicon.png" v-if="image == 'FAVICON'">
      <img src="../assets/img/examples/image_theme.png" v-if="image == 'IMG_THEME'">
      <img src="../assets/img/examples/moderator_logo.png" v-if="image == 'MODERATOR_LOGO'">
      <img src="../assets/img/examples/super_logo.png" v-if="image == 'SUPER_LOGO'">
      <img src="../assets/img/examples/theme_color.png" v-if="image == 'THEME_COLOR'">
      <img src="../assets/img/examples/buttons_color.png" v-if="image == 'BUTTONS_COLOR'">
      <img src="../assets/img/examples/welcome_title.png" v-if="image == 'WELCOME_TITLE'">
      <img src="../assets/img/examples/welcome_name.png" v-if="image == 'WELCOME_NAME'">
    </div>
  </GradientBackLayout>
</template>

<script>
  import Swal from 'sweetalert2'
  import BoxShadow                from '@/components/BoxShadow.vue';
  import GradientBackLayout       from '@/layouts/GradientBackLayout'
  import CommunityButton          from '@/components/CommunityButton';
  import Branding                 from '@/components/CommunityConfig/Branding'
  import SignupDetails            from '@/components/CommunityConfig/SignupDetails'
  import GamificationDetails      from '@/components/CommunityConfig/GamificationDetails'
  import WelcomeDetails           from '@/components/CommunityConfig/WelcomeDetails'
  import ClientsDetails           from '@/components/CommunityConfig/ClientsDetails'
  import store                    from '@/store';
  import { mapGetters }           from 'vuex';
  import moment                   from 'moment';
  import {
    fetchUserProfile,
  }  from '@/utils';
  import {
    UPDATE_PERSONALIZATION_FULL
  } from '@/store/app.module';
  import {
    COMMUNITY_UPDATE_INFO
  } from "@/store/community/info.module";
 export default {
    name: 'ProfileClient',
    components: {
      GradientBackLayout,
      CommunityButton,
      BoxShadow,
      Branding,
      SignupDetails,
      GamificationDetails,
      WelcomeDetails,
      ClientsDetails
    },


    computed: {
      ...mapGetters([
        'theme',
        'communityInfo',
        'isCommunityMainAdmin',
        'isObservator',
      ]),
      showSaveButton(){
        return [0,1,2,3].includes(this.selected)
      }
    },

    data(){
      return{
        selected:0,
        gamificationData:{
          hideGamification: true,
          pointsRedeemDate:'',
          pointsRedeemDateTo:'',
          redeemCards: []
        },
        signUpData:{
          conditions: '',
          acceptance: '',
          afterLoginBypass: false,
          allowRegistration: false,
          url: ''
        },
        welcomeData:{
          moderatorImage: "",
          moderatorInfo: "",
          videos: [],
          images: [],
          instructions: "",
          pointsExplanation: "",
          pointsCommunityActive: 0,
          pointsCommentAppreciated: 0,
          pointsActivityParticipated: 0,
          loginWelcomeTextBlack: "",
          loginWelcomeTextColor: ""
        },
        personalization:{
          loginImageUrl : '',
          logoUrl : '',
          loginText : '',
          signupText : '',
          motivationText : '',
          mainColor : '#ffffff',
          mainColorText: '#ffffff',
          textColor : '#ffffff',
          buttonColor : '#ffffff',
          favicon : '',
          moderatorLogo : '',
          loginLogoPosition : 'CENTER'
        },
        showImage: false,
        image:'',
        url:''
      }
    },

    methods: {
      setDefaultGamificationData(){
        this.gamificationData.showGamification = this.getShowGamification()
        this.gamificationData.pointsRedeemDate = this.theme.pointsRedeemDate
        this.gamificationData.pointsRedeemDateTo = this.theme.pointsRedeemDateTo
        this.gamificationData.redeemCards = this.theme.redeemCards
        this.gamificationData.privateLabs = this.theme.privateLabs
        this.gamificationData.pointsCommunityActive = this.communityInfo.pointsCommunityActive;
        this.gamificationData.pointsCommentAppreciated = this.communityInfo.pointsCommentAppreciated;
        this.gamificationData.pointsActivityParticipated = this.communityInfo.pointsActivityParticipated;
        this.gamificationData.pointsExplanation = this.communityInfo.pointsExplanation;
      },
      getShowGamification(){
        if(this.theme.hideGamification == null || this.theme.hideGamification == undefined) return false
        else return !this.theme.hideGamification
      },
      setDefaultWelcomeInfo(){
        this.welcomeData.title = this.communityInfo.title;
        this.welcomeData.moderatorImage = this.communityInfo.moderatorImage;
        this.welcomeData.moderatorInfo = this.communityInfo.moderatorInfo;
        this.welcomeData.videos = this.communityInfo.videos != undefined ? this.communityInfo.videos.map(function (v) { return { path: v }; }) : [];
        this.welcomeData.images = this.communityInfo.images != undefined ? this.communityInfo.images.map(function (img) { return { path: img }; }) : [];
        this.welcomeData.instructions = this.communityInfo.instructions;
        this.welcomeData.pointsExplanation = this.communityInfo.pointsExplanation;
        this.welcomeData.pointsCommunityActive = this.communityInfo.pointsCommunityActive;
        this.welcomeData.pointsCommentAppreciated = this.communityInfo.pointsCommentAppreciated;
        this.welcomeData.pointsActivityParticipated = this.communityInfo.pointsActivityParticipated;
        this.welcomeData.loginWelcomeTextBlack = this.communityInfo.loginWelcomeTextBlack;
        this.welcomeData.loginWelcomeTextColor = this.communityInfo.loginWelcomeTextColor;
      },
      setDefaultSignupData(){
        this.signUpData.conditions = this.theme.conditions,
        this.signUpData.acceptance = this.theme.acceptance,
        this.signUpData.afterLoginBypass = this.theme.afterLoginBypass,
        this.signUpData.allowAfterLogin = !this.theme.afterLoginBypass,
        this.signUpData.allowRegistration = this.theme.allowRegistration,
        this.signUpData.url = location.origin+'/c/'+this.communityInfo.urlToken+'/signup/'+this.communityInfo.registrationToken
      },
      setDefaultBrandingData(){
        this.personalization.loginImageUrl = this.theme.loginImageUrl,
        this.personalization.logoUrl = this.theme.logoUrl,
        this.personalization.loginText = this.theme.loginText,
        this.personalization.signupText = this.theme.signupText,
        this.personalization.motivationText = this.theme.motivationText,
        this.personalization.mainColor = this.theme.mainColor,
        this.personalization.mainColorText = this.theme.mainColorText,
        this.personalization.textColor = this.theme.textColor,
        this.personalization.buttonColor = this.theme.buttonColor,
        this.personalization.favicon = this.theme.favicon,
        this.personalization.moderatorLogo = this.theme.moderatorLogo,
        this.personalization.loginLogoPosition = this.theme.loginLogoPosition
      },
      saveData(){
        if(this.selected == 3) this.saveGamification()
        if(this.selected == 0) this.savePersonalization(this.personalization)
        if(this.selected == 1) this.savePersonalization(this.$refs.SignupDetails.getData())
        if(this.selected == 2) this.saveWelcomeData()
      },
      async saveWelcomeData(){
        let data = this.welcomeData
        data.images = data.images.map((image) => image.path);
        data.videos = data.videos.map((video) => video.path);
        await store.dispatch(COMMUNITY_UPDATE_INFO, { data: data })
        .then((data) => {
          console.log(data)
          this.successDataSave()
        });
      },
      saveGamification(){
        if(!this.gamificationData.showGamification) this.savePersonalization({hideGamification: true})
        else {
          const error = this.gamificationHasErrors()
          if(error.error) {
            Swal.fire({
              icon: 'error',
              text: error.text
            })
          }
          else{
            this.gamificationData.hideGamification = false
            this.savePersonalization(this.gamificationData)
          }
        }
      },
      gamificationHasErrors(){
        if(this.checkEmptyPoints()) return {error: true, text:this.$t('community_config_msg_error_labs')}
        if(this.gamificationData.pointsRedeemDate == null || this.gamificationData.pointsRedeemDate == undefined) return {error: true, text: this.$t('community_config_msg_error_datefrom')}
        if(this.gamificationData.pointsRedeemDateTo == null || this.gamificationData.pointsRedeemDateTo == undefined) return {error: true, text:this.$t('community_config_msg_error_dateto')}
        if(this.gamificationData.redeemCards == undefined || this.gamificationData.redeemCards.length == 0) return {error: true, text:this.$t('community_config_msg_error_cards_length')}
        if(this.gamificationData.redeemCards.length > 0){
          for(let c of this.gamificationData.redeemCards){
            if(c.name.length == 0) return {error: true, text: this.$t('community_config_msg_error_card_name')}
            if(c.logo.length == 0) return {error: true, text: this.$t('community_config_msg_error_card_logo')}
            if(c.prize.length == 0) return {error: true, text: this.$t('community_config_msg_error_card_value')}
            if(c.labs.length == 0) return {error: true, text: this.$t('community_config_msg_error_card_labs')}
            if(c.description.length < 3) return {error: true, text: this.$t('community_config_msg_error_card_desc')}
          }
        }
        return { error: false }
      },
      checkEmptyPoints(){
        const points = [
        this.gamificationData.pointsActivityParticipated,
        this.gamificationData.pointsCommentAppreciated,
        this.gamificationData.pointsCommunityActive
        ]
        return points.includes(undefined) || points.includes(null) || points.includes("")
      },
      async savePersonalization(data){
        if (this.personalization.mainColor == '#ffffff') {
          this.personalization.mainColorText = '#7f7c7c';
        } else {
          this.personalization.mainColorText = this.personalization.mainColor;
        }
        if (this.personalization.buttonColor == "#ffffff") this.personalization.buttonColor = "#000000";
        await store.dispatch( UPDATE_PERSONALIZATION_FULL, { origin : this.theme.communityId, data: data } )
          .then( async ( data ) => {
            this.successDataSave()
          } )
          .catch( (response) => {
            this.errorDataSave()
        } )
      },
      async fetchPersonalizationFull(){
        await store.dispatch( FETCH_PERSONALIZATION_FULL)
          .then( async ( data ) => {
            console.log(data)
          } )
      },
      successDataSave(){
        Swal.fire({
          text:this.$t('community_config_success'),
          icon:'success'
        }).then((result) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.overlay || 
                  result.dismiss === Swal.DismissReason.esc || result.dismiss === Swal.DismissReason.backdrop) {
            window.location.reload();
          }
        });
      },
      errorDataSave(){
        Swal.fire({
          text:this.$t('community_config_error'),
          icon:'error'
        })
      },
      openImage(event){
        this.showImage = true
        this.image = event
      },
      copyUrl(event){
          event.preventDefault()
          navigator.clipboard.writeText(this.url);
          $(".WelcomeDetails--UrlCopied").show()
          setTimeout(() => {
            $(".WelcomeDetails--UrlCopied").hide()
          }, 2000)
        },
    },
    mounted(){
      this.setDefaultBrandingData()
      this.setDefaultSignupData()
      this.setDefaultWelcomeInfo()
      this.setDefaultGamificationData()
      this.url = location.origin+'/c/'+this.communityInfo.urlToken+'/backoffice'
    }
  }
</script>
<style scoped lang="scss">
.CommunityConfig{
  &--Image{
    position:fixed;
    background:rgba(0,0,0,0.8);
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        display: block;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        border: 2px solid lightgray;
    }
  }
  &--Close{
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    &:hover{
      cursor: pointer;
      color: var(--primary-color);
    }
    i{
      display: flex;
      width: 29px;
      height: 2px;
    }
    i:first-child{
      transform: rotate(10deg);
    }
    i:last-child{
      transform: rotate(95deg) translate(9px, -1px);
    }
  }
  &--Box{
    padding: 35px;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 10px!important;
  }
  }
  &--Tabs{
    width: 205px;
    margin-top: 20px;
    z-index:1;
    @media only screen and (max-width: 600px) {
      display: flex;
  }
    i{
      display: none;
    }
    div{
      background: #F5F5F5;
      font-size: 18px;
      padding: 15px;
      color: #3E3E3E;
      margin-bottom: 10px;
      cursor: pointer;
      opacity: 0.8;
      transition: 0.2s;
      &:hover{
        opacity:1;
        color: var(--primary-color-text);
      }
      &.active{
        opacity:1;
        color: var(--primary-color-text);
        background: white;
        filter: drop-shadow(-6px 1px 10px rgba(0, 0, 0, 0.05));
      }
      @media only screen and (max-width: 600px) {
      margin:0;
  }
    }
  }
  &--Content{
    padding: 40px;
    display: flex;
    flex-direction:column;
    border: none;
    box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
    width: calc(100% - 205px);
    @media only screen and (max-width: 600px) {
      width: calc(100% - 10px)!important;

  }
  }
  &--Actions{
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    button{
        background:var(--accent-color)!important;
    }
  }
  @media screen and (max-width: 900px) {
    &--Box{
      padding: 20px;
    }
    &--Content{
      width: calc(100% - 40px);
      padding: 20px;
    }
    &--Tabs {
      width: 40px;
      div{
        padding: 10px;
      }
      span{
        display: none;
      }
      i{
        display: flex;
      }
    }
  }
}
.WelcomeDetails {
    font-family: 'Roboto Condensed';
    
    label {
        font-size: 18px;
        color: #3E3E3E;
    }

    &--LoginUrl {
        margin-top:1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        a {
            color: #969696;
        }
        i {
            margin-right: 5px;
            color: var(--primary-color);
        }
    }

    &--UrlCopied {
        display: none;
        position: absolute;
        border: 1px solid #F7F4F8;
        box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
        padding: 6px 10px;
        font-size: 14px;
        background: white;
        font-family: Lato;
        width: fit-content;
        top: 25%;
        right: 50%;
        i {
            font-size: 20px;
        }
    }
}

</style>
